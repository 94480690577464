import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import MainBanner from "../pages/slider/MainBanner";
import Casino from "../pages/casino-home/Casino";
import SportAccordian from "../components/SportAccordian";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import moment from "moment";
import helpers from "../Utils/helpers";
import NoEvent from "../components/NoEvent";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { AiFillStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CurrentNews from "../components/CurrentNews";
import GameMenu from "../components/GameMenu";
import logo from "../assets/images/loader.gif";

import { FaSearch, FaBell, FaCog, FaTv, FaAngleLeft } from "react-icons/fa";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import { io } from "socket.io-client";
import Favourite from "./Favourtie";

const Home = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
    messagelist,
    setAnnouncement,
  } = useContext(AuthContext);
  let navigate = useNavigate();
  const { setLimit } = useContext(BetSlipContext);
  const [list, setList] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [updatedList, setUpdatedList] = useState([]);
  const [activeFav, setActiveFav] = useState([]);
  const [odds, setOdds] = useState([]);
  const [selected, setSelected] = useState({});
  const [loader, setLoader] = useState(false);
  const [eventIdPremium, setEventIdPremium] = useState([]);
  const [updated, setUpdated] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [activeKeysAll, setActiveKeysAll] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });

  const [cricketScore, setCricketScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };
  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameTab,
      type: keyTime,
      userId: user?.user?._id || "",
    });
    if (data?.success) {
      let newResult = data?.results
        ?.map((res) => {
          return res?.matchList;
        })
        .flat();
      setNewResult(newResult);

      setEventIdPremium(
        newResult?.map((res) => {
          return res?.eventId;
        })
      );
      let temp = data?.results
        ?.filter((res) => {
          return res?.hasMatchOddsInList;
        })
        ?.map((res) => {
          return res?.seriesId;
        });
      setActiveKeys(temp);
      setActiveFav(
        data?.results
          ?.map((res) => {
            return res?.matchList;
          })
          .flat()
          ?.filter((res) => {
            return res?.isFavourite;
          })
          ?.map((res) => {
            return res?.eventId;
          })
      );
      setList(filteredArray(data?.results));
      setLoader(false);
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };

  const leagueData = async () => {
    const { status, data } = await apiGet(
      apiPath.seriesList + "?gameType="+gameTab+"&domain=bajifair.live"
    );
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        setGameTab(gameTab);
        return filteredArray(
          data?.results?.filter((res) => {
            return res?.gameType == gameTab;
          })
        );
      });
    }
  };
  useEffect(() => {
    if (keyTime == "leagues") {
      leagueData();
    } else {
      setList([]);
      getData();
    }
  }, [gameTab, keyTime]);

  const handleSelect = (event, type) => {
    if (gameTab !== "all") {
      if (activeKeys.includes(event)) {
        setActiveKeys(activeKeys.filter((res, index) => res !== event));
      } else {
        setActiveKeys([...activeKeys, event]);
      }
    } else {
      updateActive(event, type);
    }
  };
  const obj = {
    cricket: activeKeysAll?.cricket || [],
    tennis: activeKeysAll?.tennis || [],
    soccer: activeKeysAll?.soccer || [],
  };
  const updateActive = (event, type) => {
    if (obj[type].includes(event)) {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: obj[type].filter((res, index) => res !== event),
        });
      }
    } else {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [...activeKeysAll?.cricket, event],
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [...activeKeysAll?.tennis, event],
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [...activeKeysAll?.soccer, event],
        });
      }
    }
  };

  const handelChanges = (type) => {
    let temp = show ? false : true;
    setShow(temp);
    if (temp) {
      if (gameTab !== "all") {
        setActiveKeys(
          list?.map((res, index) => {
            return res?.seriesId;
          })
        );
      }
    } else {
      console.log("111");
      setActiveKeys([]);
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id
          ?.map((res) => {
            return res?.marketId;
          })
          ?.join(",")}&multi=true&eventIds=${id
          ?.map((res) => {
            return res?.eventId;
          })
          ?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (newResult?.length > 0 && !parly) {
      getMatchOddsMulti(newResult);
    }
  }, [newResult]);

  const [multiOddsPremium, setMultiOddsPremium] = useState([]);
  const getPremiumMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy + `?events=${id?.join(",")}&parly=true&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMultiOddsPremium(socket_data);
      }
    }
  };

  useEffect(() => {
    if (eventIdPremium?.length > 0 && parly) {
      getPremiumMulti(eventIdPremium);
    }
  }, [eventIdPremium, parly]);

  useEffect(() => {
    if (eventIdPremium?.length > 0) {
      let interval = setInterval(() => {
        if (parly) {
          getPremiumMulti(eventIdPremium);
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [eventIdPremium, parly]);

  console.log(multiOddsPremium, "multiOddsPremium");
  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    if (newResult?.length > 0) {
      let interval = setInterval(() => {
        if (!parly) {
          getMatchOddsMulti(newResult);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [newResult, parly]);

  const [count, setCount] = useState({
    cricketInplayCount: 0,
    soccerInplayCount: 0,
    tennisInplayCount: 0,
  });

  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.inPlayCountMobile,
      {
        type: keyTime,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setCount(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    inPlayCount();
  }, [keyTime]);
  return (
    <LayoutNew visibilityType={true}>
      {searchTab ? (
        <SearchBar />
      ) : (
        <>
          <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          />

          <div class="ftype d-flex justify-content-between">
            <ul>
              <li
                className={keyTime == "in_play" ? "active" : ""}
                onClick={() => {
                  setKeyTime("in_play");
                  setParly(false);
                }}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>
                Live{" "}
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={keyTime == "active" ? "active" : ""}
                onClick={() => {
                  setGameTab("cricket");
                  setKeyTime("active");
                  setParly(false);
                }}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>{" "}
                Upcoming
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
              {!parly && (
                <li
                  className={keyTime == "leagues" ? "active" : ""}
                  onClick={() => {
                    setKeyTime("leagues");
                    setParly(false);
                  }}
                >
                  <span class="left-view">
                    <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                      _mstVisible="6"
                    >
                      <title _mstHash="188" _mstVisible="7">
                        tab-radius-l
                      </title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="true"
                        class="tab-bg"
                        _mstVisible="7"
                      >
                        <path
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-l"
                          transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                          _mstVisible="8"
                        ></path>
                      </g>
                    </svg>
                  </span>{" "}
                  Leagues{" "}
                  <span class="right-view">
                    <svg
                      data-v-16f79fce=""
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                    >
                      <title data-v-16f79fce="">tab-radius-r</title>
                      <g
                        data-v-16f79fce=""
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="false"
                        class="tab-bg"
                      >
                        <path
                          data-v-16f79fce=""
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-r"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              )}

              <li
                onClick={() => setParly(!parly)}
                className={parly ? "parlyatab active" : "parlyatab"}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>{" "}
                {parly ? <i class="icon-check"></i> : ""} Parlay
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
            </ul>
            <div className="settingtab d-flex">
              <span>
                <FaSearch
                  onClick={() => setSearchTab(true)}
                  className="ms-auto"
                />
              </span>
              <span>
                <FaBell className="ms-auto" />
              </span>
              <span>
                <FaCog className="ms-auto" />
              </span>
            </div>
          </div>
          <GameMenu
            count={count}
            game={gameTab}
            type="home"
            setGame={setGameTab}
          />
          {gameTab == "myFav" ? (
            <Favourite />
          ) : loader ? (
            <div className="bkash-loader apiloader">
              <img src={logo} loop autoPlay muted style={{ height: `150px` }} />
            </div>
          ) : keyTime !== "leagues" ? (
            <>
              <div className="all-complete">
                <DropdownButton
                  id="abc"
                  title={
                    <div>
                      <BiSortAlt2 className="me-1" />
                      by Competition
                    </div>
                  }
                  className=""
                >
                  <Dropdown.Item>by Time</Dropdown.Item>
                </DropdownButton>
                {gameTab !== "all" && (
                  <div onClick={handelChanges} class="all-complete-all">
                    <span class="mr-1">Collapse</span>
                    {!show ? (
                      <MdKeyboardDoubleArrowDown />
                    ) : (
                      <MdKeyboardDoubleArrowUp />
                    )}
                  </div>
                )}
              </div>
              <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                <div>
                  {list?.length > 0 ? (
                    list?.map((item, index) => {
                      return (
                        <SportAccordian
                          profileData={profileData}
                          user={user}
                          keyTime={keyTime}
                          activeKeys={activeKeys}
                          handleSelect={handleSelect}
                          type={gameTab}
                          showOdds={
                            parly ? false : gameTab == "all" ? false : true
                          }
                          gameTab={gameTab}
                          activeFav={activeFav}
                          setActiveFav={setActiveFav}
                          odds={odds}
                          item={item}
                          index={index}
                          setLimit={setLimit}
                          score={[...score,...cricketScore]}
                          multiOddsPremium={multiOddsPremium}
                        />
                      );
                    })
                  ) : (
                    <NoEvent />
                  )}
                </div>
              </div>
            </>
          ) : loader ? (
            <div>
              <div className="bkash-loader apiloader">
                <img
                  src={logo}
                  loop
                  autoPlay
                  muted
                  style={{ height: `150px` }}
                />
              </div>
            </div>
          ) : (
            isEmpty(selected) && (
              <div>
                {updatedList?.length > 0 ? (
                  <div className="d-flex p-3 leage-list main-casino-wrapper">
                    {/* <aside className="text-center">
                  <ul className="p-0 mb-0">
                    <li>C</li>
                    <li>I</li>
                    <li>O</li>
                  </ul>
                </aside> */}
                    <div
                      className={`main-list w-100 ${
                        updatedList?.length > 7 ? "mb-5" : ""
                      }`}
                    >
                      <h6 className="mb-3">Popular</h6>
                      {updatedList?.map((item, index) => {
                        return (
                          <div className="w-100 mb-1 match-list">
                            <ul className="p-0 m-0">
                              <li onClick={() => setSelected(item)}>
                                <i class="icon-top"></i> {item?.seriesName}
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                    <NoEvent />
                  </div>
                )}
              </div>
            )
          )}

          {/* second-level */}
          {!isEmpty(selected) && (
            <div className="second-level-leage">
              <div className="balance-label position-relative text-start ps-5">
                <button
                  onClick={() => setSelected({})}
                  className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                >
                  <FaAngleLeft className="fs-3" />
                </button>
                {selected?.seriesName}
              </div>

              <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                <div
                  className={`main-list w-100 ${
                    selected?.matchList?.length > 7 ? "mb-5" : ""
                  }`}
                >
                  {selected?.matchList?.map((item, index) => {
                    return (
                      <div className="w-100 mb-3 match-list">
                        <ul className="p-0 m-0">
                          <li
                            onClick={() =>
                              navigate(
                                `/full-market/${item?.marketId}/${item?.eventId}`
                              )
                            }
                          >
                            {item?.runners?.map((res) => {
                              return (
                                <span className="d-block">
                                  {res?.RunnerName}
                                </span>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </LayoutNew>
  );
};

export default Home;
