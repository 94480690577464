import React, {
  startTransition,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import Info from "./Info";
import { isEmpty } from "lodash";
import BetSlipContext from "../context/BetSlipContext";
import useScroll from "./Scroll";
import { useNavigate } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import secureLocalStorage from "react-secure-storage";
import { premiumCategoryArray } from "../Utils/constants";
import { toast } from "react-toastify";
import SportBookInner from "./SportBookInner";

const SportsBook = ({
  handleShowInfo,
  data,
  user,
  detail,
  profileData,
  setBetSlipObject,
  setShowBetDialog,
  setLimit,
  scrollHeight,
  position,
}) => {
  const { parly, parlyBet, setParlyBet } = useContext(AuthContext);
  const naviagte = useNavigate();
  const [selectSpark, setSelectSpark] = useState([0, 1, 2, 3, 4]);
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  const myRef = useRef(null);
  const [selectSport, setSelectSport] = useState("All");
  const [scrollId, setScrollId] = useScroll(myRef);
  const [selected, setSelected] = useState("");
  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
  };
  const [localLimit, setLocalLimit] = useState({});
  const handelChange = (odds, item, res, index) => {
    if (!isEmpty(user)) {
      if (odds > 0) {
        if (!parly) {
          setScrollId(index);
          setShowBetDialog(true);
          setLimit(localLimit);
          setBetSlipObject((prev) => ({
            ...prev,
            odds: odds,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
            teamName: res?.selectionName,
            item: { ...item },
            playType: "sportBook",
            object: {
              ...res,
            },
          }));
        } else {
          setParlyBet((current) => {
            let newObj = {
              ...item,
              sportsBookSelectionObject: res,
              selectObj: res?.id,
              newEventId: detail?.eventId,
              eventName: detail?.eventName,
              marketId: detail?.marketId,
            };
            if (current?.length > 0) {
              if (
                current?.filter((temp) => {
                  return temp?.newEventId == detail?.eventId;
                })?.length > 0
              ) {
                if (
                  current?.filter((temp) => {
                    return temp?.newEventId !== detail?.eventId;
                  })?.length > 0
                ) {
                  secureLocalStorage.setItem("parlyBet", [
                    ...current?.filter((temp) => {
                      return temp?.newEventId !== detail?.eventId;
                    }),
                    newObj,
                  ]);
                  return [
                    ...current?.filter((temp) => {
                      return temp?.newEventId !== detail?.eventId;
                    }),
                    newObj,
                  ];
                } else {
                  secureLocalStorage.setItem("parlyBet", [newObj]);
                  return [newObj];
                }
              } else {
                secureLocalStorage.setItem("parlyBet", [...current, newObj]);
                return [...current, newObj];
              }
            } else {
              secureLocalStorage.setItem("parlyBet", [newObj]);
              return [newObj];
            }
          });
        }
      }
    } else {
      naviagte("/login");
    }
  };
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "sportBook" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const sportBookCheck = (parlyBet, detail, res) => {
    if (isEmpty(user)) {
      return "";
    } else {
      let find = parlyBet?.find((res) => {
        return res?.newEventId == detail?.eventId;
      });
      if (!isEmpty(find)) {
        return find?.sportsBookSelectionObject?.id == res?.id
          ? "active-sportBook"
          : "";
      } else {
        return "";
      }
    }
  };

  const [updatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    let filtered = data?.filter((res) => {
      return res?.marketName?.toLowerCase()?.includes("1x2");
    });
    let filteredNot = data?.filter((res) => {
      return !res?.marketName?.toLowerCase()?.includes("1x2");
    });
    let newUpdatedResult = [...filtered, ...filteredNot];
    if (selectSport == "Innings") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return res?.marketName?.toLowerCase()?.includes("innings");
        })
      );
    } else if (selectSport == "Over") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            res?.marketName?.toLowerCase()?.includes("over") &&
            !res?.marketName?.toLowerCase()?.includes("winner")
          );
        })
      );
    } else if (selectSport == "Match") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            res?.marketName?.toLowerCase()?.includes("winner") ||
            res?.marketName?.toLowerCase()?.includes("innings")
          );
        })
      );
    } else if (selectSport == "Batsman") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            !res?.marketName?.toLowerCase()?.includes("over") &&
            res?.marketName?.toLowerCase()?.includes("innings") &&
            res?.marketName?.toLowerCase()?.includes("total")
          );
        })
      );
    } else {
      setUpdatedData(newUpdatedResult);
    }
  }, [selectSport, data]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setSelectSpark([]);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <div className="sports-tab-panel py-2 px-3 fancy-sports-inner-tab position-relative">
        <Slider {...settings}>
          {premiumCategoryArray?.length > 0 &&
            premiumCategoryArray?.map((res) => {
              return (
                <div onClick={() => setSelectSport(res?.name)}>
                  <h3 className={selectSport == res?.name ? "active" : ""}>
                    {res?.name}
                  </h3>
                </div>
              );
            })}
        </Slider>

        <Button
          className="position-absolute end-0 p-2 top-0 info-btn border-0"
          onClick={() => handleShowInfo()}
        >
          <BsFillInfoCircleFill />
        </Button>
      </div>

      <div className="sport-listing">
        {updatedData?.map((item, index) => {
          let condition =
            item?.marketStatus == 9 ||
            item?.marketStatus == 2 ||
            item?.marketStatus == 3 ||
            item?.marketStatus == 4;
          if (item?.sportsBookSelection?.length > 0) {
            return (
              <Accordion
                onSelect={(e) => {
                  if (selectSpark?.includes(e)) {
                    setSelectSpark((prev) => {
                      return prev?.filter((res) => {
                        return res !== e;
                      });
                    });
                  } else {
                    setSelectSpark((prev) => {
                      return [...prev, e];
                    });
                    setTimeout(() => {
                      setSelectSpark((prev) => {
                        return prev?.filter((res) => {
                          return res !== e;
                        });
                      });
                    }, 1000);
                  }
                }}
                defaultActiveKey={[0, 1, 2, 3, 4]}
              >
                <Accordion.Item eventKey={index}>
                  <Accordion.Header
                    style={{ scrollMarginTop: scrollHeight }}
                    ref={index == scrollId ? myRef : null}
                  >
                    <AiOutlineStar size={25} style={{ margin: "1px 6px" }} />

                    {item?.marketName}
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div className="sports-listing-score">
                      <Row
                        className={`gx-1 ${
                          item?.apiSiteStatus !== "ACTIVE"
                            ? "sports-book-disabled"
                            : condition
                            ? "sports-book-disabled"
                            : ""
                        }`}
                      >
                        {(item?.apiSiteStatus !== "ACTIVE" || condition) && (
                          <strong className="sports-book-disabled-text">
                            {item?.apiSiteStatus !== "ACTIVE"
                              ? "Suspended"
                              : item?.marketStatus == 9
                              ? "Ball Running"
                              : item?.marketStatus == 2
                              ? "In Active"
                              : item?.marketStatus == 3
                              ? "Suspended"
                              : item?.marketStatus == 4
                              ? "Closed"
                              : ""}
                          </strong>
                        )}
                        {item?.sportsBookSelection?.map((res) => {
                          let newPosition = "";
                          if (
                            showBetDialog &&
                            betSlipObject?.playType == "sportBook" &&
                            item?.id == selected
                          ) {
                            let backProfit =
                              (Number(betSlipObject?.odds) - 1) *
                              Number(betSlipObject?.bidPrice);
                            let backLoss = Number(betSlipObject?.bidPrice);
                            if (res?.id == betSlipObject?.object?.id) {
                              newPosition =
                                Math.abs(res?.position) > 0
                                  ? Number(backProfit) + Number(res.position)
                                  : Number(backProfit);
                            } else {
                              newPosition =
                                Math.abs(res?.position) > 0
                                  ? Number(backLoss) - Number(res?.position)
                                  : -Number(backLoss);
                            }
                          }
                          let activeClass = parly
                            ? sportBookCheck(parlyBet, detail, res)
                            : betSlipObject?.teamName == res?.selectionName &&
                              betSlipObject?.odds == res?.odds &&
                              item?.marketName ==
                                betSlipObject?.item?.marketName &&
                              showBetDialog
                            ? "back-active"
                            : "";

                          let conditionPosition =
                            showBetDialog &&
                            betSlipObject?.playType == "sportBook" &&
                            item?.id == selected &&
                            Number(betSlipObject?.bidPrice) > 0
                              ? true
                              : false;

                          let oldObject = item?.oldSportsBookSelection?.find(
                            (obj) => {
                              return obj?.id == res?.id;
                            }
                          );
                          let conditionShowPositon = !isEmpty(
                            position?.find((res) => {
                              return (
                                res?.marketId ==
                                `${item?.betfairEventId}S${item?.id}`
                              );
                            })
                          );

                          return (
                            <SportBookInner
                              oldObject={oldObject}
                              conditionShowPositon={conditionShowPositon}
                              res={res}
                              handelChange={handelChange}
                              item={item}
                              index={index}
                              parly={parly}
                              activeClass={activeClass}
                              newPosition={newPosition}
                              conditionPosition={conditionPosition}
                              setSelected={setSelected}
                              selectSpark={selectSpark}
                            />
                          );
                        })}
                      </Row>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          }
        })}
      </div>
    </>
  );
};

export default SportsBook;
