import React, { useContext, useEffect, useRef,useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import bkashLogo from "../assets/images/logo-bajifair.png";
import rightArrow from "../assets/images/right-arrow.png";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import LoginSlider from "./LoginSlider";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
const ForgotPassword = () => {
  const { sendOTP,setBr, lang, setEn } = useContext(AuthContext);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
    },
  });
  const navigate = useNavigate();
  const onSubmit = (body) => {
   
  };

  const [getType, setType] = useState("email");

  return (
    <>
      <div className="register-content">
     
        {getType=="email"?
        <div className="login-form active" >
        <div className="forgot-back-header menu-header">
          <div className="left-arrow">
            <span onClick={() => navigate("/")}>
              <img src={rightArrow} alt="" />
            </span>
          </div>
          <p>{t("Forgot_Password")}</p>
          
        </div>
        <div className="reg-data">
            <div  class="tab-btn tab-btn-page">
       
       <div  class={getType=="email"?"btn active":"btn"}  onClick={() => setType("email")}><div class="text">Email </div></div>
       <div  class={getType=="mobile"?"btn active":"btn"} onClick={() => setType("mobile")}><div  class="text">SMS </div></div>
       </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <Form.Group className="d-flex login-data-d">
                  <Form.Label>{t("Username")}</Form.Label>
                  <Form.Control
                      type="text"
                      autocomplete="off"
                      className={errors.username ? "is-invalid" : ""}
                      {...register("username", {
                          required: "Please enter Username",
                      })}
                      placeholder="Username"
                  />
                 
                </Form.Group>
                {/* {errors.username && errors.username?.message && (
                    <label className="invalid-feedback text-left">
                      {errors.username.message}
                    </label>
                  )} */}
              <Form.Group className="d-flex login-data-d">
                <Form.Label> Email</Form.Label>
                    <div style={{ position:'relative',width:`100%`}}>
                      <Form.Control
                      type="text"
                      placeholder="input valid email"
                      className={errors.password ? " is-invalid " : ""}
                        {...register("email", {
                          required: "Please enter email",
                         
                        })}
                    />
                    
                    </div>
                    {/* {errors.email && errors.email.message && (
                      <label className="invalid-feedback text-left">
                        {errors.email.message}
                      </label>
                    )} */}
              </Form.Group>
            </div>
           
           

            <button type="submit" className="submit-btn">
              <span>{t("Confirm")}</span>
            </button>
           
          </Form>
        </div>
      </div>
      :
      <div className="login-form active" >
        <div className="forgot-back-header menu-header">
          <div className="left-arrow">
            <span onClick={() => navigate("/")}>
              <img src={rightArrow} alt="" />
            </span>
          </div>
          <p>{t("Forgot_Password")}</p>
          
        </div>
        <div className="reg-data">
        <div  class="tab-btn tab-btn-page">
       
       <div  class={getType=="email"?"btn active":"btn"}  onClick={() => setType("email")}><div class="text">Email </div></div>
       <div  class={getType=="mobile"?"btn active":"btn"} onClick={() => setType("mobile")}><div  class="text">SMS </div></div>
       </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <Form.Group className="d-flex login-data-d">
                  <Form.Label>{t("Username")}</Form.Label>
                  <Form.Control
                      type="text"
                      autocomplete="off"
                      className={errors.username ? "is-invalid" : ""}
                      {...register("username", {
                          required: "Please enter Username",
                      })}
                      placeholder="Username"
                  />
                 
                </Form.Group>
                {/* {errors.username && errors.username?.message && (
                    <label className="invalid-feedback text-left">
                      {errors.username.message}
                    </label>
                  )} */}
               <Form.Group className="form-group d-flex login-data-d">
            <Form.Label>{t("Mobile_Number")}</Form.Label>
            <Controller
              className="form-group d-flex"
              control={control}
              name="mobile"
              rules={{
                required: "Please enter mobile number.",
                validate: (value) => {
                  let inputValue = value
                    ?.toString()
                    ?.slice(
                      inputRef?.current?.state?.selectedCountry?.countryCode
                        ?.length,
                      value?.length
                    );
                  if (inputValue?.length < 8) {
                    return "Mobile number must contain 5 digit";
                  } else if (inputValue?.length > 12) {
                    return "Mobile number should not exceed 15 digit";
                  } else {
                    return true;
                  }
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <PhoneInput
                  {...field}
                  inputExtraProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                  ref={inputRef}
                  inputStyle={{
                    width: "100%",
                    height: "38px",
                  }}
                  country={"bd"}
                  enableSearch
                  countryCodeEditable={true}
                />
              )}
            />
          </Form.Group>
            </div>
           
           

            <button type="submit" className="submit-btn">
              <span>{t("Confirm")}</span>
            </button>
           
          </Form>
        </div>
      </div>

                }

      </div>
      
    </>
  );
};

export default ForgotPassword;
