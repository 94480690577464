import React from "react";
import BackLayHome from "./BackLayHome";
import { Accordion } from "react-bootstrap";
import { priceFormat } from "../Utils/constants";
import { FavouriteDisplayMatch } from "./FavouriteDisplayMatch";

export const SportAccordianFavourite = ({
  label,
  totalMatched,
  children,
  addToFavioute,
  data,
  labelShow,
}) => {
  return (
    <div className="bookmaker p-2">
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div
              className="d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              {label == "SportBook" || label == "Fancy" ? (
                ""
              ) : (
                <div
                  onClick={() =>
                    addToFavioute(
                      data?.eventId,
                      labelShow,
                      data?.marketId || data?.bookmakerMarketId,
                      data?.eventType,
                      labelShow !== "Match Odds" ? "" : label
                    )
                  }
                  style={{ width: "3.8rem" }}
                  className={`fave  bg-star-sprit-black  start-0 ${
                    true ? "animate" : ""
                  }`}
                ></div>
              )}
              {label}
              <div
                className="balance-label-inner"
                style={{ color: "rgba(30, 30, 30, 0.4)", marginLeft: "8px" }}
              >
                {totalMatched > 0 && (
                  <>
                    <span>Matched</span>
                    <strong
                      className=""
                      style={{
                        fontSize: `.7142857143rem`,
                        padding: `.5rem`,
                      }}
                    >
                      USD ${priceFormat(totalMatched)}
                    </strong>
                  </>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">{children}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
